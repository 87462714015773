import '../styles/globals.css'
// Load DM Sans typeface
import 'typeface-dm-sans';

// Load other package css file
import 'react-multi-carousel/lib/styles.css';
import 'react-modal-video/css/modal-video.min.css';
import 'rc-drawer/assets/index.css';

// import styles
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';

import 'bootstrap/dist/css/bootstrap.min.css';

import ErrorHandler from 'components/error-handler';


function MyApp({ Component, pageProps }) {
  return (
    <ErrorHandler><Component {...pageProps} /></ErrorHandler>
  )
}

export default MyApp
