import React from 'react'
import { AppInsightsErrorBoundary } from "@microsoft/applicationinsights-react-js";
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { APPLICATION_INSIGHTS_CONNECTION } from "config"

export default function ErrorHandler(props) {
    var reactPlugin = new ReactPlugin();
    var appInsights = new ApplicationInsights({
        config: {
            connectionString: APPLICATION_INSIGHTS_CONNECTION,
            extensions: [reactPlugin]
        }
    });
    appInsights.loadAppInsights()

    return (
        <AppInsightsErrorBoundary onError={() => <h3>Something went wrong. Please try again or contact support if issue persists.</h3>} appInsights={reactPlugin}>
            <React.Fragment>{props.children}</React.Fragment>
        </AppInsightsErrorBoundary>
    )
}